<template>
    <div>
        <p class="text-xl border-b pl-2 mb-2">{{ header }}</p>
        <table class="w-full">
            <thead>
                <th class="px-2 text-sm">Konto</th>
                <th class="px-2 text-sm" align="right">Debet</th>
                <th class="px-2 text-sm" align="right">Kredit</th>
            </thead>
            <tr v-for="item in itemsData" :key="item.account" class="border text-sm">
                <td class="px-2 py-1">{{ accountText(item.account) }}</td>
                <td class="px-2 py-1" align="right">{{ item.debit }}</td>
                <td class="px-2 py-1" align="right">{{ item.credit }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        itemsData: {
            type: Array,
            default: () => [],
        },
        accountsData: {
            type: Array,
            default: () => [],
        },
        header: {
            type: String,
            default: "",
        },
    },

    methods: {
        accountText(numberProp) {
            if (!this.accountsData || this.accountsData.length == 0) return "";
            const { name, number } = this.accountsData.filter(item => item.number == numberProp)[0];
            return `${number} ${name}`;
        },
    },
};
</script>
